.react-datepicker__header {
  background-color: white;
  border-bottom: 2px solid #4baac8;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected,
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #4baac8;
}
.react-datepicker__day:hover {
  background-color: #e5f8fe;
}
.react-datepicker__day--selected:hover {
  background-color: #f8997b;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  bottom: 1px;
}

.react-datepicker {
  border: none;
  box-shadow: 0px 3px 3px #ababab;
  font-family: sans-serif;
  color: #585858;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__navigation--next {
  border-left-color: #4baac8;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #f8997b;
}

.react-datepicker__navigation--previous {
  border-right-color: #4baac8;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #f8997b;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #1b7b99;
}
